import React, { useState, useEffect } from "react";
import API_DOMAIN from "../config/config";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    padding: 26,
    position: "relative",
  },
  section: {
    flexDirection: "column",
    flexGrow: 1,
    border: 1,
    display: "flex",
  },
  text: {
    fontSize: 13,
    fontFamily: "Times-Roman",
    textAlign: "center",
    borderBottom: 1,
    width: "100%",
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: "Times-Roman",
    borderBottom: 1,
    width: "100%",
    padding: 2,
  },
  head: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },

  invoice: {
    flex: 200,
    fontWeight: "bolder",
    fontSize: 13,
  },
  // invoice-title start
  // table start
  table: {
    display: "flex",
    borderBottom: 0.5,
    padding: 6,
    justifyContent: "flex-end",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: "Times-Roman",
  },

  // table end
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    borderBottom: 1,
  },
  producttitle: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: 5,
    borderBottom: 1,
  },
  productbody: {
    fontSize: 8,
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
    padding: 5,
  },
  bodydescription: {
    height: 178,
  },
  no: {
    width: "10%",
    textAlign: "center",
    padding: 5,
  },
  particular: {
    width: "30%",
    textAlign: "left",
    padding: 5,
    flexWrap: "wrap",
  },
  hsn: {
    width: "10%",
    textAlign: "center",
    padding: 5,
  },
  qty: {
    width: "10%",
    textAlign: "center",
    padding: 5,
  },
  rateinctax: {
    width: "15%",
    textAlign: "center",
    padding: 5,
  },
  rate: {
    width: "15%",
    textAlign: "center",
    padding: 5,
  },
  amount: {
    width: "15%",
    textAlign: "center",
    padding: 5,
  },
  // product end
  // footer start
  footer: {
    fontSize: 9,
    display: "flex",
    justifyContent: "flex-end",
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerleft: {
    height: 62,
    borderBottom: 0.5,
  },
  footerCell: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    paddingVertical: 5,
    width: "100%",
  },
  right: {
    textAlign: "right",
  },
  totalBox: {
    position: "absolute",
  },
  w70: {
    width: "70%",
  },
  w30: {
    width: "30%",
  },

  // footer end
  // price word start
  priceRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    borderBottom: 1,
    flexGrow: 15,
  },
  priceCell: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    paddingVertical: 5,
    flexGrow: 10,
    borderRight: 1,
    padding: 4,
    flexShrink: 1, // Ensures the text shrinks to fit
  },
  overall: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    paddingVertical: 5,
    padding: 5,
    flexGrow: 2.5,
    marginTop: 6,
  },
  paymentDetails: {
    marginTop: 10,
    marginBottom: 10,
  },
  paymentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 2,
  },
  paymentMethod: {
    fontSize: 9,
    fontFamily: "Times-Roman",
  },
  paymentAmount: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    textAlign: "right",
  },

  // price word end
  // signs start
  signbox: {
    fontSize: 9,
    display: "flex",
  },
  signdesk: {
    height: 55,
  },
  signbill: {
    flexDirection: "row",
  },
  customsign: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    paddingVertical: 5,
    flexGrow: 200,
    marginTop: 12,
  },
  docs: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    paddingVertical: 5,
    padding: 5,
    marginTop: 12,
  },
  declration: {
    fontSize: 8,
    padding: 1,
  },

  boxdetails: {
    display: "flex",
    flexDirection: "row",
    borderBottom: 1,
  },
  boxdetailss: {
    display: "flex",
    flexDirection: "row",
  },
  box: {
    fontSize: 9,
    width: "50%",
    padding: 5,
  },
  brdright: { borderRight: 1 },
  brdrights: { borderRight: 1, borderBottom: 1 },
  brdtop: {
    borderTopWidth: 1,
    borderTopColor: "#000",
    borderTopStyle: "solid",
    width: "50%",
    paddingBottom: 1,
  },
  print: {
    fontSize: 9,
    textAlign: "right",
  },
  companyname: {
    fontWeight: "bold",
    fontSize: 9,
  },
  billed: {
    fontSize: 11,
    fontWeight: "bold",
  },
  boldText: {
    fontFamily: "Times-Roman",
    fontWeight: "bold",
    fontSize: 11,
  },
  ewayno: {
    marginBottom: 1,
    fontSize: 11,
    fontWeight: "bold",
  },
  stateof: {
    fontSize: 11,
    fontWeight: "bold",
  },
  remarks: {
    fontSize: 6,
  },
  // signs end
});
const Pdf = ({ data, type }) => {
  console.log("data", data);
  const IGST = data.product.reduce(
    (acc, product) => acc + parseFloat(product.IGST),
    0
  );
  const SGST = data.product.reduce(
    (acc, product) => acc + parseFloat(product.SGST),
    0
  );
  const CGST = data.product.reduce(
    (acc, product) => acc + parseFloat(product.CGST),
    0
  );

  function convertRupeesToWordsWithBreaks(amount) {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Lakh", "Crore"]; // Adjusted for Indian system

    // Convert a three-digit group into words
    function convertGroup(number, thousandIndex) {
      if (number === 0) return "";

      let str = "000" + number;
      str = str.slice(-3);

      const hundred = parseInt(str[0], 10);
      const ten = parseInt(str[1], 10);
      const one = parseInt(str[2], 10);

      let words = [];

      if (hundred > 0) {
        words.push(ones[hundred] + " Hundred");
      }

      if (ten === 1) {
        words.push(teens[one]);
      } else {
        if (ten > 0) {
          words.push(tens[ten]);
        }
        if (one > 0) {
          words.push(ones[one]);
        }
      }

      if (thousandIndex > 0 && words.length > 0) {
        words.push(thousands[thousandIndex]);
      }

      return words.join(" ");
    }

    // Handle the integer part of the amount
    function convertIntegerPart(integerPart) {
      const groups = [];
      let thousandIndex = 0;

      while (integerPart > 0) {
        groups.push(
          convertGroup(
            integerPart % (thousandIndex === 1 ? 100 : 1000),
            thousandIndex
          )
        );
        integerPart = Math.floor(
          integerPart / (thousandIndex === 1 ? 100 : 1000)
        );
        thousandIndex++;
      }

      // Insert newline character before the last three digits
      const resultGroups = groups.reverse().filter((group) => group !== "");
      const lastGroupIndex = resultGroups.length - 1;
      const formattedGroups = resultGroups.map(
        (group, index) => (index === lastGroupIndex ? group : group) // Unnecessary but ensures each group properly.
      );

      return formattedGroups.join(" ");
    }

    // Handle the decimal part of the amount
    function convertDecimalPart(decimalPart) {
      if (decimalPart === 0) {
        return "";
      }

      let paise = Math.round(decimalPart * 100);
      return convertGroup(paise, 0) + " Paise";
    }

    // Split the amount into integer and decimal parts
    const integerPart = Math.floor(amount);
    const decimalPart = amount - integerPart;

    // Convert both parts
    const integerWords = convertIntegerPart(integerPart);
    const decimalWords = convertDecimalPart(decimalPart);

    // Combine the results with line breaks
    const result =
      integerWords + " Rupees" + (decimalWords ? "\nAnd " + decimalWords : "");
    return result.trim();
  }
  const [userdata, setUserData] = useState([]);

  const filteredUserData = userdata.filter(
    (item) => item.company_id === data.party_details.company_id
  );

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/company.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      //setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);

      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      //setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const words = convertRupeesToWordsWithBreaks(data.total);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View></View>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headertext}>
              <Text style={styles.print}>[{type}] </Text>
              <View style={[styles.head]}>
                <Text style={styles.invoice}>Tax Invoice</Text>
              </View>
            </View>
          </View>
          {/* Table for address */}
          <View style={[styles.boxdetailss]}>
            <View style={[styles.box, styles.brdrights]}>
              <Text style={[styles.billed, styles.boldText]}>Billed By :  {data.company_details.company_name}</Text>

              <Text>{data.company_details.address}</Text>
              <Text>{data.company_details.city}</Text>
              <Text>{data.company_details.state}</Text>
              <Text>GST :{data.company_details.gst_no}</Text>
              <Text>Ph :{data.company_details.mobile_number}</Text>
            </View>
            <View style={styles.box}>

              <Text style={styles.ewayno}>Invoice No: {data.bill_no}</Text>
              <Text style={styles.ewayno}>Date: {data.bill_date}</Text>
              <Text style={styles.ewayno}>
                State Of Supply: {data.state_of_supply}
              </Text>
              <Text style={styles.ewayno}>E-way Bill No: {data.eway_no}</Text>
              <Text style={styles.ewayno}>Vehicle No: {data.vechile_no}</Text>

            </View>
          </View>
          <View style={[styles.boxdetails]}>
            <View style={[styles.box, styles.brdright]}>
              <Text style={[styles.billed, styles.bold]}>
                Ship To : {data.party_details.party_name}
              </Text>
              <Text>Address : {data.party_details.billing_address != "" ? data.party_details.billing_address : data.billing_address}</Text>
              <Text>Ph :{data.party_details.mobile_number}</Text>
              <Text>GST :{data.party_details.gst_no}</Text>
            </View>
            <View style={[styles.box, styles.brdtop]}>
              <Text style={[styles.billed, styles.bold]}>
                Shipping Address :
              </Text>
              <Text>
                {data.party_details.shipp_address != ""
                  ? data.party_details.shipp_address
                  : data.shipp_address}
              </Text>
            </View>
          </View>
          <View style={styles.producthead}>
            <View style={styles.producttitle}>
              <Text style={styles.no}>S No</Text>
              <Text style={styles.particular}>Particular</Text>
              <Text style={styles.hsn}>HSN</Text>
              <Text style={styles.qty}>Qty</Text>

              <Text style={styles.rate}>Rate without Tax</Text>
              <Text style={styles.qty}>GST %</Text>
              <Text style={styles.rate}>Rate With Tax</Text>
              <Text style={styles.amount}>Amount</Text>
            </View>
            <View></View>
            <View style={styles.bodydescription}>
              {data.product.map((product, index) => (
                <View style={styles.productbody} key={index}>
                  <Text style={styles.no}>{index + 1}</Text>
                  <Text style={styles.particular}>{product.product_name}</Text>
                  <Text style={styles.hsn}>{product.hsn_no}</Text>
                  <Text style={styles.qty}>{product.qty}</Text>

                  <Text style={styles.rate}>
                    {product.total_amt && product.tax_amt
                      ? (Number(product.price_unit) -
                        Number(product.tax_amt) / product.qty).toFixed(2)
                      : 0}
                  </Text>
                  <Text style={styles.qty}>{product.tax_percentage} %</Text>
                  <Text style={styles.rateinctax}>{product.price_unit}</Text>
                  <Text style={styles.amount}>
                    {/* {product.price_unit} */}
                    {product.total_amt && product.tax_amt && product.qty
                      ? (Number(product.qty) *
                        (Number(product.price_unit))).toFixed(2)
                      : 0}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View style={[styles.boxdetails]}>

            <View style={[styles.box, styles.brdright, styles.w70]}>
              <Text>Bank Name : {data.company_details.bank_name}</Text>
              <Text>Account No: {data.company_details.acc_no}</Text>
              <Text>IFSC : {data.company_details.ifsc_code}</Text>
            </View>
            {parseFloat(IGST) > 0 ? (
              <View style={[styles.box, styles.w30]}>

                <Text> Total : {data.sum_total - IGST}</Text>
                <Text> IGST : {IGST.toFixed(2)}</Text>
                <Text> Discount : {data.discount != "" ? data.discount : ""}</Text>
              </View>
            ) : (
              <View style={[styles.box, styles.w30]}>

                <Text> Total : {(data.sum_total - CGST - SGST).toFixed(2)}</Text>
                <Text> CGST : {CGST.toFixed(2)}</Text>
                <Text> SGST : {SGST.toFixed(2)}</Text>
                <Text> Discount : {data.discount != "" ? data.discount : ""}</Text>
              </View>
            )}
          </View>
          <View style={styles.footer}>
            <View style={styles.priceleft}>
              <View style={styles.priceRow}>
                <Text style={[styles.priceCell, styles.w70]}>Amount in words : {words} Only</Text>

                <Text style={[styles.overall, styles.w30]}>
                  NETT AMOUNT :{" "}
                  {Number(data.sum_total - data.discount || 0).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.boxdetails]}>
            <Text style={[styles.billed, styles.bold]}>
              Payment Details:
            </Text>

            {/* Check and display payment details */}
            {data.payment_method && (
              <View style={styles.paymentDetails}>
                {JSON.parse(data.payment_method).map((payment, index) => (
                  <View style={styles.paymentRow} key={index}>
                    <Text style={styles.paymentMethod}>
                      {payment.payment_method.charAt(0).toUpperCase() + payment.payment_method.slice(1)}:
                    </Text>
                    <Text style={styles.paymentAmount}>
                      {Number(payment.amount).toFixed(2)} Rs
                    </Text>
                  </View>
                ))}
              </View>
            )}
          
            {/* Check and display remarks */}
            {data.remark && (
              <>
                <Text style={[styles.billed, styles.bold]}>
                  Remarks:
                </Text>
                <View style={styles.paymentDetails}>
                  <View style={styles.paymentRow}>
                    <Text style={styles.paymentMethod}>
                      {data.remark}
                    </Text>
                  </View>
                </View>
              </>
            )}
          </View>


          <View style={styles.signbox}>
            <View style={styles.signdesk}>
              <View style={styles.signbill}>
                <View style={styles.customsign}>
                  <Text> Customer Sign</Text>
                </View>
                <Text style={styles.docs}>
                  For {data.company_details.company_name}{" "}
                </Text>
              </View>
              <View>
                <Text style={styles.declration}>
                  {" "}
                  We Declare that this invoice shows the actual price of the
                  goods described and that all particulars are true and correct
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default Pdf;
