import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const downloadPurchaseExcel = (data, fromdate, todate) => {
  const formattedFromDate = fromdate ? new Date(fromdate).toLocaleDateString() : "N/A";
  const formattedToDate = todate ? new Date(todate).toLocaleDateString() : "N/A";

  // Map the data into a format suitable for Excel
  const processedData = data.map((item, index) => {
    const sumTotal = parseFloat(item.sum_total) || 0; // Ensure numeric value
    const total = parseFloat(item.total) || 0; // Ensure numeric value
    const gst = (total - sumTotal).toFixed(2); // Calculate GST
    return {
      "S No": index + 1,
      "Bill Date": item.bill_date || "N/A",
      "Stock Date": item.stock_date || "N/A",
      "Supplier Inv.No": item.bill_no || "N/A",
      "Supplier Name": item.party_details?.party_name || "N/A",
      "SubTotal": sumTotal.toFixed(2),
      "Tax Amount (GST)": gst,
      "Grand Total": total.toFixed(2),
    };
  });

  // Add summary row at the bottom
  const subTotal = data.reduce(
    (acc, item) => acc + (parseFloat(item.sum_total) || 0),
    0
  );
  const subGrandTotal = data.reduce(
    (acc, item) => acc + (parseFloat(item.total) || 0),
    0
  );
  const subGST = data.reduce(
    (acc, item) => acc + ((parseFloat(item.total) || 0) - (parseFloat(item.sum_total) || 0)),
    0
  );

  processedData.push({
    "S No": "Total",
    "Bill Date": "",
    "Stock Date": "",
    "Supplier Inv.No": "",
    "Supplier Name": "",
    "SubTotal": subTotal.toFixed(2),
    "Tax Amount (GST)": subGST.toFixed(2),
    "Grand Total": subGrandTotal.toFixed(2),
  });

  // Create workbook and worksheet
  const worksheet = XLSX.utils.json_to_sheet(processedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Purchase Report");

  // Add metadata
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [`Purchase Report: ${formattedFromDate} to ${formattedToDate}`],
      [],
    ],
    { origin: "A1" }
  );

  // Adjust column width for better readability
  const colWidths = [
    { wpx: 50 }, // S No
    { wpx: 100 }, // Bill Date
    { wpx: 100 }, // Stock Date
    { wpx: 150 }, // Supplier Inv.No
    { wpx: 200 }, // Supplier Name
    { wpx: 100 }, // SubTotal
    { wpx: 120 }, // Tax Amount
    { wpx: 120 }, // Grand Total
  ];
  worksheet["!cols"] = colWidths;

  // Convert to Excel file and trigger download
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `PurchaseReport_${formattedFromDate}_to_${formattedToDate}.xlsx`);
};
