import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { Buttons, ClickButton } from "../components/Buttons";
import { TableUI } from "../components/Tables";

import { TextInputForm } from "../components/Forms";
import { PageHead, PageTitle } from "../components/PageTitle";

import { VscEyeClosed, VscEye } from "react-icons/vsc";

import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_DOMAIN from "../config/config";

const ProductHistory = ["S.No", "Name", "MobileNumber", "Action"];

const User = () => {
  const location = useLocation();

  const [show, setShow] = useState(false);

  // useState(() => {
  //     if (!localStorage.getItem('auth')) navigate("/login");
  // }, [navigate]);

  const handleClose = () => {
    setShow(false);
    location.state = [];
  };
  const handleShow = () => {
    setCrtUser(null);
    setFormData({
      name: "",
      phone_number: "",
      password: "",
    });
    setShow(true);
  };
  const [showPassword, setShowPassword] = useState(false);
  const [crtuser, setCrtUser] = useState(null);

  // Function to open modal
  const handleEditClick = (rowdata) => {
    setCrtUser(rowdata);
    setShow(true);
  };
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    password: "",
  });
  const [userData, setUserData] = useState([]);

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    if (crtuser != null) {
      setCrtUser({
        ...crtuser,
        [fieldName]: value,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: value,
      });
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/user.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setUserData(responseData.body.user);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
  }, []);

  const errorAlert = (input) => {
    toast.error(input, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successAlert = (success) => {
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async () => {
    try {
      if (
        formData.name === "" ||
        formData.password === "" ||
        formData.phone_number === ""
      ) {
        if (formData.name === "") {
          errorAlert("Name is Must");
        } else if (formData.phone_number === "") {
          errorAlert("Mobile Number is Must");
        } else if (formData.password === "") {
          errorAlert("Password is Must");
        }
      } else {
        const response = await fetch(
          `${API_DOMAIN}/user.php`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const responseData = await response.json();

        if (responseData.head.code === 200) {
          setFormData({
            name: "",
            phone_number: "",
            password: "",
          });
          fetchData();
          successAlert(responseData.head.msg);
          setTimeout(() => {
            handleClose(); // Close modal after 10 seconds
          }, 2000);
        } else if (responseData.head.code === 400) {
          toast.error(responseData.head.msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateSubmit = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/user.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // Include the company ID in the request
          user_id: crtuser.user_id,
          name: crtuser.name,
          phone_number: crtuser.phone_number,
          password: crtuser.password,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        fetchData();
        toast.success("User updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          handleClose();
        }, 2000);

        // Navigate to the user list page after a delay
      } else {
        console.error(
          responseData.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.msg);
    }

   
  };

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  useEffect(() => {
    const checkSession = async () => {
      try {
        // Check session logic here, e.g., fetch from local storage or server
        const session = localStorage.getItem("session");
        if (!session) {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking session:", error.message);
        setIsLoggedIn(false);
      }
    };
    checkSession();
  }, []);
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return (
    <div>
      <div>
        <Container fluid>
          <div className="main my-3">
            <Row>
              <Col lg="7" md="6" xs="12" className="align-self-center">
                <PageTitle PageTitle={"User"} />
              </Col>
              <Col lg="5" md="6" xs="12">
                <div className="text-end py-3">
                  <Buttons lable={<>Add New</>} onClick={handleShow} />
                </div>
              </Col>
              {/* <Col lg={12} md={12} xs={12} className='py-2 text-end'>
                                <Pagnation />
                            </Col> */}
              <Col lg={12} md={12} xs={12} className="py-2">
                <div className="py-2 c-table">
                  <TableUI
                    headers={ProductHistory}
                    body={userData}
                    type="USER"
                    handleEditClick={handleEditClick}
                    onDelete={fetchData}
                  />
                </div>
              </Col>
              {/* <Col lg={12} md={12} xs={12}>
                                <Pagnation />
                            </Col> */}
            </Row>
          </div>
        </Container>
        <>
          <Modal show={show} onHide={handleClose} size="xl" centered scrollable>
            <Modal.Header>
              <Modal.Title>
                <PageHead
                  pagehead={`User${crtuser != null ? "Update" : "Creation"}`}
                  onClick={handleClose}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container fluid>
                <Row>
                  <Col lg="4" md="6" xs="12" className="py-3">
                    <TextInputForm
                      labelname={"Name"}
                      placeholder={"Name"}
                      name="name"
                      value={crtuser != null ? crtuser.name : formData.name}
                      onChange={(e) => handleChange(e, "name")}
                    ></TextInputForm>
                  </Col>
                  <Col lg="4" md="6" xs="12" className="py-3">
                    <TextInputForm
                      labelname={"MobileNumber"}
                      placeholder={"MobileNumber"}
                      type={"phone_number"}
                      name="phone_number"
                      value={
                        crtuser != null
                          ? crtuser.phone_number
                          : formData.phone_number
                      }
                      onChange={(e) => handleChange(e, "phone_number")}
                    ></TextInputForm>
                  </Col>
                  <Col lg="4" md="6" xs="12" className="py-3">
                    <TextInputForm
                      placeholder={"Password"}
                      labelname={"Password"}
                      suffix_icon={
                        showPassword ? (
                          <VscEye onClick={() => setShowPassword(false)} />
                        ) : (
                          <VscEyeClosed onClick={() => setShowPassword(true)} />
                        )
                      }
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      value={
                        crtuser != null ? crtuser.password : formData.password
                      }
                      onChange={(e) => handleChange(e, "password")}
                    ></TextInputForm>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
                <div className="text-center">
                  {crtuser != null ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>

                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={handleClose}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Submit</>}
                          onClick={handleSubmit}
                        ></ClickButton>
                      </span>

                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={handleClose}
                        ></ClickButton>
                      </span>
                    </>
                  )}
                </div>
              </Col>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    </div>
  );
};

export default User;
