import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const downloadStockExcel = (data) => {
  // Map the data into a format suitable for Excel
  const processedData = data.map((item, index) => ({
    "S No": index + 1,
    "Product Name": item.product_name || "N/A",
    Category: item.category_name || "N/A",
    Stock: item.crt_stock || 0,
    "Sales Rate": item.unit_rate || 0,
  }));

  // Create workbook and worksheet
  const worksheet = XLSX.utils.json_to_sheet(processedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Report");

  // Add metadata
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [["Stock Report"], []], // Add a title row and a blank row
    { origin: "A1" }
  );

  // Adjust column widths for better readability
  const colWidths = [
    { wpx: 50 }, // S No
    { wpx: 200 }, // Product Name
    { wpx: 150 }, // Category
    { wpx: 100 }, // Stock
    { wpx: 120 }, // Sales Rate
  ];
  worksheet["!cols"] = colWidths;

  // Convert to Excel file and trigger download
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `StockReport.xlsx`);
};
