import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const ExpenseExcel = (data, category) => {
  // Process data
  const processedData = data.map((item, index) => {
    const categoryItem = category.find((cat) => cat.category_id === item.category_id);
    return {
      "S No": index + 1,
      "Expense Date": item.bill_date,
      "Expense Category": categoryItem ? categoryItem.category_name : "Unknown",
      "Name": item.party_name,
      "Amount": item.total,
    };
  });

  // Create a new workbook and worksheet
  const worksheet = XLSX.utils.json_to_sheet(processedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Expense Report");

  // Convert to Excel file and trigger download
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "ExpenseReport.xlsx");
};
