import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PageTitle } from "../components/PageTitle";
import Select from "react-select";
import { Tables } from "../components/Tables";
import API_DOMAIN from "../config/config";
const options = [
  { value: "Today", label: "Today" },
  { value: "This Week", label: "This Week" },
  { value: "This Month", label: "This Month" },
  { value: "Last Month", label: "Last Month" },
];

const ProductTablehead = ["Product Name", "Current Stock", "Minimum Stock"];

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [salesdata, setSalesData] = useState([]);
  const [expitemData, setexpitemData] = useState([]);
  const [payindata, setpayinData] = useState([]);
  const [payoutdata, setpayoutData] = useState([]);
  const [estimationData, setEstiData] = useState([]);

  const [totalsales, settotalsales] = useState(0.0);
  const [totalExpense, settotalExpense] = useState(0.0);
  const [payintotal, setpayintotal] = useState(0.0);
  const [purchasetotal, setpurchasetotal] = useState(0.0);

  const [salefilterValue, setsaleFilterValue] = useState("month");
  const [expenseFilterValue, setExpenseFilterValue] = useState("month");
  const [recivedFilterValue, setrecivedFilterValue] = useState("month");
  const [purchaseFilterValue, setpurchaseFilterValue] = useState("month");
  const [ProductDataupdate, setProductData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDateReceived, setFromDateReceived] = useState("");
  const [toDateReceived, setToDateReceived] = useState("");
  const [fromDatePurchase, setFromDatePurchase] = useState("");
  const [toDatePurchase, setToDatePurchase] = useState("");
  const [fromDateexpense, setFromDateexpense] = useState("");
  const [toDateexpense, setToDateexpense] = useState("");

  const lowStockProducts = ProductDataupdate.filter(
    (product) => product.crt_stock < product.min_stock
  );
  const lowStockProductData = lowStockProducts.map((product) => ({
    values: [product.product_name, product.crt_stock, product.min_stock],
  }));
  console.log("ProductData", ProductDataupdate);
  const fetchDataproduct = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/products.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      setLoading(false);
      console.log(responseData);
      if (responseData.status === 200) {
        setProductData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };

  const filteredSaleData = salesdata.filter((item) => {
    const currentDate = new Date();
    const itemDate = new Date(item.created_date);

    console.log(itemDate);
    const from = new Date(fromDate);
    const to = new Date(toDate);

    // If fromDate and toDate are selected, filter by that range
    if (fromDate && toDate) {
      return itemDate >= from && itemDate <= to;
    }
    if (salefilterValue === "Today") {
      // Filter data for today
      return itemDate.toDateString() === currentDate.toDateString();
    } else if (salefilterValue === "This Week") {
      // Filter data for this week
      const currentDayOfWeek = currentDate.getDay();
      const startOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDayOfWeek)
      );
      const endOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() + (6 - currentDayOfWeek))
      );
      return itemDate >= startOfWeek && itemDate <= endOfWeek;
    } else if (salefilterValue === "This Month") {
      // Filter data for this month
      const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
      const currentYear = currentDate.getFullYear();
      return (
        itemDate.getMonth() === currentMonth &&
        itemDate.getFullYear() === currentYear
      );
    } else if (salefilterValue === "Last Month") {
      // Filter data for last month
      const currentMonth = currentDate.getMonth(); // Note: January is 0
      const currentYear = currentDate.getFullYear();
      if (currentMonth === 0) {
        return (
          itemDate.getMonth() + 1 === 12 &&
          itemDate.getFullYear() === currentYear - 1
        );
      } else {
        return (
          itemDate.getMonth() + 1 === currentMonth &&
          itemDate.getFullYear() === currentYear
        );
      }
    }
    return true; // Return true if no filter is applied
  });
  console.log("filteredSaleData", filteredSaleData);
  const totalSalesamt = filteredSaleData.reduce(
    (acc, item) => acc + parseFloat(item.total),
    0
  );

  console.log(totalSalesamt);
  const filteredrecivedData = payindata.filter((item) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
    const currentYear = currentDate.getFullYear();
    const itemDate = new Date(item.created_date);
    const itemDay = itemDate.getDate();
    const itemMonth = itemDate.getMonth() + 1;
    const itemYear = itemDate.getFullYear();

    const from = new Date(fromDateReceived);
    const to = new Date(toDateReceived);

    // If fromDate and toDate are selected, filter by that range
    if (fromDateReceived && toDateReceived) {
      return itemDate >= from && itemDate <= to;
    }

    if (recivedFilterValue === "Today") {
      // Filter data for today
      return (
        itemDay === currentDay &&
        itemMonth === currentMonth &&
        itemYear === currentYear
      );
    } else if (recivedFilterValue === "This Week") {
      // Filter data for this week
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Set to the start of the week (Sunday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Saturday)

      return itemDate >= startOfWeek && itemDate <= endOfWeek;
    } else if (recivedFilterValue === "This Month") {
      // Filter data for this month
      return itemMonth === currentMonth && itemYear === currentYear;
    } else if (recivedFilterValue === "Last Month") {
      // Filter data for last month
      const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;

      return itemMonth === lastMonth && itemYear === lastMonthYear;
    }
    return true; // Return true if no filter is applied
  });

  const totalrecivedamt = filteredrecivedData.reduce(
    (acc, item) => acc + item.paid,
    0
  );
console.log('expitemData',expitemData);
  const filteexpenseData = expitemData.filter((item) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
    const currentYear = currentDate.getFullYear();
    const itemDate = new Date(item.bill_date);
    const itemDay = itemDate.getDate();
    const itemMonth = itemDate.getMonth() + 1;
    const itemYear = itemDate.getFullYear();

    const from = new Date(fromDateexpense);
    const to = new Date(toDateexpense);

    // If fromDate and toDate are selected, filter by that range
    if (fromDateexpense && toDateexpense) {
      return itemDate >= from && itemDate <= to;
    }

    if (recivedFilterValue === "Today") {
      // Filter data for today
      return (
        itemDay === currentDay &&
        itemMonth === currentMonth &&
        itemYear === currentYear
      );
    } else if (recivedFilterValue === "This Week") {
      // Filter data for this week
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Set to the start of the week (Sunday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Saturday)

      return itemDate >= startOfWeek && itemDate <= endOfWeek;
    } else if (recivedFilterValue === "This Month") {
      // Filter data for this month
      return itemMonth === currentMonth && itemYear === currentYear;
    } else if (recivedFilterValue === "Last Month") {
      // Filter data for last month
      const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;

      return itemMonth === lastMonth && itemYear === lastMonthYear;
    }
    return true; // Return true if no filter is applied
  });

  const totalexpensedamt = filteexpenseData.reduce(
    (acc, item) => acc + item.total,
    0
  );

  const filtepurchaseData = userData.filter((item) => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1; // Note: January is 0
    const currentYear = currentDate.getFullYear();
    const itemDate = new Date(item.created_date);
    const itemDay = itemDate.getDate();
    const itemMonth = itemDate.getMonth() + 1;
    const itemYear = itemDate.getFullYear();
    const from = new Date(fromDatePurchase);
    const to = new Date(toDatePurchase);

    // If fromDate and toDate are selected, filter by that range
    if (fromDatePurchase && toDatePurchase) {
      return itemDate >= from && itemDate <= to;
    }

    if (purchaseFilterValue === "Today") {
      // Filter data for today
      return (
        itemDay === currentDay &&
        itemMonth === currentMonth &&
        itemYear === currentYear
      );
    } else if (purchaseFilterValue === "This Week") {
      // Filter data for this week
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Set to the start of the week (Sunday)
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Saturday)

      return itemDate >= startOfWeek && itemDate <= endOfWeek;
    } else if (purchaseFilterValue === "This Month") {
      // Filter data for this month
      return itemMonth === currentMonth && itemYear === currentYear;
    } else if (purchaseFilterValue === "Last Month") {
      // Filter data for last month
      const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;

      return itemMonth === lastMonth && itemYear === lastMonthYear;
    }
    return true; // Return true if no filter is applied
  });

  const totalpurchaseamt = filtepurchaseData.reduce(
    (acc, item) => acc + parseFloat(item.total),
    0
  );

  useEffect(() => {
    if (salesdata.length > 0) {
      calculate_total(salesdata, "sales");
    }
    if (expitemData.length > 0) {
      calculate_total(expitemData, "expenses");
    }
    if (payindata.length > 0) {
      calculate_total(payindata, "payin");
    }
    if (userData.length > 0) {
      calculate_total(userData, "purchase");
    }
  }, [salesdata, expitemData, payindata, userData]);
  const fetchData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/purchase_bill.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setUserData(responseData.data);
        console.log(setUserData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchsalesData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/salesinvoice.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            party_id: "",
            from_date: "",
            to_date: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setSalesData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchExpensesItemData = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(
        `${API_DOMAIN}/expenseitem.php?id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        // Set userData to the array of purchase parties
        setexpitemData(responseData.data);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchDatapayin = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/payin.php?id=${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setpayinData(responseData.data);
        console.log(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchDatapayout = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const response = await fetch(`${API_DOMAIN}/payout.php?id=${companyId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      if (responseData.status === 200) {
        // Set userData to the array of purchase parties
        setpayoutData(responseData.data);
        console.log(responseData);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const calculate_total = (data, type) => {
    if (type === "sales") {
      console.log("salesrecive");
      var totalSales = data.reduce(
        (acc, sale) => acc + parseFloat(sale.total),
        0
      );
      settotalsales(totalSales);
    } else if (type === "expenses") {
      var totalExpense = data.reduce(
        (acc, expense) => acc + parseFloat(expense.total),
        0
      );
      settotalExpense(totalExpense);
    } else if (type === "payin") {
      var totalpayin = data.reduce(
        (acc, payin) => acc + parseFloat(payin.paid),
        0
      );
      setpayintotal(totalpayin);
    } else if (type === "purchase") {
      var totalpurchase = data.reduce(
        (acc, purchase) => acc + parseFloat(purchase.total),
        0
      );
      setpurchasetotal(totalpurchase);
    }
  };
  const handleFilterChange = (type) => (selectedOption) => {
    if (type === "sale") {
      setsaleFilterValue(selectedOption.value);
    } else if (type === "expense") {
      setExpenseFilterValue(selectedOption.value);
    } else if (type === "recived") {
      setrecivedFilterValue(selectedOption.value);
    } else if (type === "purchase") {
      setpurchaseFilterValue(selectedOption.value);
    }
  };
  useEffect(() => {
    fetchData();
    fetchsalesData();
    fetchExpensesItemData();
    fetchDatapayin();
    fetchDatapayout();
    fetchDataproduct();
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <PageTitle PageTitle={"Dashboard"} />
          </Col>
        </Row>
      </Container>
      <div>
        <Container fluid>
          <Row>
            <Col lg="8">
              <Row>
                <Col lg="6" className="py-3">
                  <div className="main">
                    <Row>
                      {/* Title Section */}
                      <Col lg="12" className="py-3">
                        <div className="fs-4 ">Sales Value</div>
                      </Col>

                      {/* From Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">From Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </Col>

                      {/* To Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">To Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </Col>

                      {/* Date Range Summary */}
                      <Col lg="12" className="py-3">
                        <div>
                          <strong>Sales Value: ₹{totalSalesamt.toFixed(2)}</strong>{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg="6" className="py-3">
                  <div className="main">
                    <Row>
                      <Col lg="12" className="py-3">
                        <div className="fs-4">Expense</div>
                      </Col>
                      {/* From Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">From Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={fromDateexpense}
                          onChange={(e) => setFromDateexpense(e.target.value)}
                        />
                      </Col>

                      {/* To Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">To Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={toDateexpense}
                          onChange={(e) => setToDateexpense(e.target.value)}
                        />
                      </Col>

                      {/* Date Range Summary */}
                      <Col lg="12" className="py-3">
                        <div>
                          <strong>Expense Value: ₹{totalexpensedamt.toFixed(2)}</strong>{" "}
                        </div>
                      </Col>
                     
                    </Row>
                  </div>
                </Col>
                <Col lg="6" className="py-3">
                  <div className="main">
                    <Row>
                      {/* Title Section */}
                      <Col lg="12" className="py-3">
                        <div className="fs-4">Received Cash</div>
                      </Col>

                      {/* From Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">From Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={fromDateReceived}
                          onChange={(e) => setFromDateReceived(e.target.value)}
                        />
                      </Col>

                      {/* To Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">To Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={toDateReceived}
                          onChange={(e) => setToDateReceived(e.target.value)}
                        />
                      </Col>

                      {/* Date Range Summary */}
                      <Col lg="12" className="py-3">
                        <div>
                          <strong>Received Cash: ₹{totalrecivedamt.toFixed(2)}</strong>{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg="6" className="py-3">
                  <div className="main">
                    <Row>
                      {/* Title Section */}
                      <Col lg="12" className="py-3">
                        <div className="fs-4">Purchase Value</div>
                      </Col>

                      {/* From Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">From Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={fromDatePurchase}
                          onChange={(e) => setFromDatePurchase(e.target.value)}
                        />
                      </Col>

                      {/* To Date Selection */}
                      <Col lg="6" className="py-3">
                        <div className="fs-6">To Date</div>
                        <input
                          type="date"
                          className="form-control"
                          value={toDatePurchase}
                          onChange={(e) => setToDatePurchase(e.target.value)}
                        />
                      </Col>

                      {/* Date Range Summary */}
                      <Col lg="12" className="py-3">
                        <div>
                          <strong>Purchase Value: ₹{totalpurchaseamt.toFixed(2)}</strong>{" "}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="4" className="py-3">
              <div className="main">
                <div className="text-center py-3"> Low Stock Value</div>
                <div className="scroll">
                  <Tables
                    headers={ProductTablehead}
                    body={lowStockProductData}
                  ></Tables>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
