import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const downloadSalesExcel = (data, fromdate, todate) => {
  const formattedFromDate = fromdate ? new Date(fromdate).toLocaleDateString() : "N/A";
  const formattedToDate = todate ? new Date(todate).toLocaleDateString() : "N/A";

  const processedData = data.map((item, index) => {
    const gst = Array.isArray(item.product)
      ? item.product.reduce((acc, product) => acc + parseFloat(product.tax_amt || 0), 0).toFixed(2)
      : 0;
    const balance = parseFloat(item.total || 0) - parseFloat(item.paid || 0);
  
    // Process payment methods
    const paymentMethod = (() => {
      try {
        // Ensure item.payment_method is parsed into an array
        const paymentArray = Array.isArray(item.payment_method)
          ? item.payment_method
          : typeof item.payment_method === "string"
          ? JSON.parse(item.payment_method) // Parse the JSON string
          : [];
    
        // Process the array
        return paymentArray.length > 0
          ? paymentArray
              .map(pm => {
                const method = pm.payment_method || "Unknown";
                const amount = pm.amount || "0";
                return `${method} - ${amount}`;
              })
              .join(", ")
          : "N/A";
      } catch (error) {
        console.error("Error parsing payment_method:", error, item.payment_method);
        return "N/A"; // Return N/A if parsing fails
      }
    })();
    
  
    console.log("Processed payment method:", paymentMethod);
  
    return {
      "S No": index + 1,
      Date: item.bill_date || "N/A",
      "Receipt No": item.bill_no || "N/A",
      "Party Name": item.party_details?.party_name || "N/A",
      "Phone Number": item.party_details?.mobile_number || "N/A",
      "SubTotal": parseFloat(item.sum_total || 0).toFixed(2),
      "Inc Tax Amount": gst,
      Discount: parseFloat(item.discount || 0).toFixed(2),
      "Grand Total": parseFloat(item.total || 0).toFixed(2),
      Paid: parseFloat(item.paid || 0).toFixed(2),
      Balance: balance.toFixed(2),
      "Payment Method": paymentMethod, // Updated
      "Remark": item.remark || "N/A",
    };
  });
  
  
  
  

  // Calculate summary values
  const subTotal = data.reduce((acc, item) => acc + parseFloat(item.sum_total || 0), 0);
  const subGrandTotal = data.reduce((acc, item) => acc + parseFloat(item.total || 0), 0);
  const discount = data.reduce((acc, item) => acc + parseFloat(item.discount || 0), 0);
  const totalTaxAmount = data.reduce((acc, item) => {
    if (Array.isArray(item.product)) {
      return acc + item.product.reduce((taxAcc, product) => taxAcc + parseFloat(product.tax_amt || 0), 0);
    }
    return acc;
  }, 0);

  // Add summary row
  processedData.push({
    "S No": "Total",
    Date: "",
    "Receipt No": "",
    "Party Name": "",
    "Phone Number": "",
    "SubTotal": subTotal.toFixed(2),
    "Inc Tax Amount": totalTaxAmount.toFixed(2),
    Discount: discount.toFixed(2),
    "Grand Total": subGrandTotal.toFixed(2),
    Paid: "", // Optional: Can calculate total paid if required
    Balance: "", // Optional: Can calculate total balance if required
    "Payment Method": "",
    "remark": "",
  });

  // Create workbook and worksheet
  const worksheet = XLSX.utils.json_to_sheet(processedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Report");

  // Add metadata
  XLSX.utils.sheet_add_aoa(
    worksheet,
    [
      [`Sales Report: ${formattedFromDate} to ${formattedToDate}`],
      [],
    ],
    { origin: "A1" }
  );

  // Adjust column widths
  const colWidths = [
    { wpx: 50 }, // S No
    { wpx: 100 }, // Date
    { wpx: 120 }, // Receipt No
    { wpx: 200 }, // Party Name
    { wpx: 150 }, // Phone Number
    { wpx: 100 }, // SubTotal
    { wpx: 120 }, // Inc Tax Amount
    { wpx: 100 }, // Discount
    { wpx: 120 }, // Grand Total
    { wpx: 100 }, // Paid
    { wpx: 100 }, // Balance
    { wpx: 150 }, // Payment Method
    { wpx: 150 }, // Payment Method
  ];
  worksheet["!cols"] = colWidths;

  // Convert to Excel file and trigger download
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `SalesReport_${formattedFromDate}_to_${formattedToDate}.xlsx`);
};
