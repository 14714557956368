import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 12,
  },
  section: {
    flexDirection: "column",
    flexGrow: 1,
    border: 1,
    display: "flex",
  },
  text: {
    fontSize: 13,
    fontFamily: "Times-Roman",
    textAlign: "center",
    borderBottom: 1,
    width: "100%",
  },
  // invoice-title start
  headertext: {
    fontSize: 10,
    fontFamily: "Times-Roman",
    borderBottom: 1,
    width: "100%",
    padding: 6,
  },
  head: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
  },
  date: {
    fontSize: 10,
    width: "50%",
    padding: 0,
    textAlign: "right",
  },
  title: {
    fontWeight: "bolder",
    fontSize: 13,
    textAlign: "center",
    width: "100%",
  },
  name: {
    fontSize: 10,
    width: "50%",
    textAlign: "left",
  },
  // invoice-title start
  // table start
  table: {
    display: "flex",
    padding: 6,
    justifyContent: "flex-end",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tableCell: {
    flex: 4,
    fontSize: 9,
    fontFamily: "Times-Roman",
  },
  // table end
  // product start
  producthead: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    borderBottom: 1,
  },
  producttitle: {
    fontSize: 9,
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: 1,
  },
  productbody: {
    fontSize: 8,
    fontFamily: "Times-Roman",
    display: "flex",
    flexDirection: "row",
  },
  no: {
    width: "50%",
    borderRight: 1,
    padding: 5,
  },
  datarow: {
    width: "100%",
    borderRight: 1,
    padding: 5,
  },
  dataend: {
    width: "100%",
    padding: 5,
  },
  // product end
});
const ExpensePDF = ({ data,category }) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={styles.headertext}>
              <View style={styles.head}>
                <Text style={styles.title}>Expense Report</Text>
              </View>
            </View>
            
          </View>
          <View style={styles.producthead}>
            <View style={styles.producttitle}>
              <Text style={styles.no}>S No</Text>
              <Text style={styles.datarow}>Expense Date</Text>
              <Text style={styles.datarow}>Expense Category</Text>
              <Text style={styles.datarow}>Name</Text>
              <Text style={styles.dataend}>Amount</Text>
            </View>
            <View style={styles.bodydescription}>
              {data.map((product, index) => {
                const categoryItem = category.find(
                    (cat) => cat.category_id === product.category_id
                  );
                return (

                <View style={styles.productbody} key={index}>
                  <Text style={styles.no}>{index + 1}</Text>
                  <Text style={styles.datarow}>{product.bill_date}</Text>
                  <Text style={styles.datarow}>{categoryItem ? categoryItem.category_name : 'Unknown'}</Text>
                  <Text style={styles.datarow}>{product.party_name}</Text>
                  <Text style={styles.dataend}>{product.total}</Text>
                </View>
              )})}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default ExpensePDF;
